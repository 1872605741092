import React from "react";
import { Route, Routes } from "react-router-dom";
import Sleep from "./pages/Sleep";
import Error404 from "./pages/Error404";
import Lyrics from "./pages/Lyrics";
import OldSleep from "./pages/OldSleep";
import Power from "./pages/Power";
import Home from "./pages/Home";

export default function Main() {
    return (<div className="Routes">
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/sleep" element={<Sleep />} />
            <Route path="/old-sleep" element={<OldSleep />} />
            <Route path="/lyrics" element={<Lyrics />} />
            <Route path="/power" element={<Power />} />
            <Route path="*" element={<Error404 />} />
        </Routes>
    </div>)
}