import React, { useState } from "react";
import { pad, reformatDate, incrementDate } from "../Methods";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import { Line } from 'react-chartjs-2';

export default function Power() {
    const [loadBool, setLoadBool] = useState(false);
    const [powerChart, setPowerChart] = useState(<div></div>);
    const [solarChart, setSolarChart] = useState(<div></div>);

    if (!loadBool) {
        setLoadBool(true);
        ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler)
        fetch(`https://api.xn--ddk7b.com/api/v1/power/all`).then(r => r.json()).then(data => {
            const sortedData = [...data.records].sort((a, b) => a.timestamp - b.timestamp);
            const dayMap = [];

            let currDate = `${pad(sortedData[0].day)}/${pad(sortedData[0].month)}/20${sortedData[0].year}`;
            let endDate = `${pad(sortedData[sortedData.length - 1].day)}/${pad(sortedData[sortedData.length - 1].month)}/20${sortedData[sortedData.length - 1].year}`;

            while (currDate != incrementDate(endDate)) {
                dayMap.push({ date: reformatDate(currDate), power: null, solar: null });
                currDate = incrementDate(currDate);
            }

            for (let i = 0; i < sortedData.length; i++) {
                const recordDate = `20${sortedData[i].year}/${pad(sortedData[i].month)}/${pad(sortedData[i].day)}`;
                const recordPower = parseInt(sortedData[i].power);
                const recordSolar = parseInt(sortedData[i].solar);

                for (let j = 0; j < dayMap.length; j++) {
                    if (dayMap[j].date === recordDate) {
                        if (dayMap[j].power === null || recordPower > dayMap[j].power) dayMap[j].power = recordPower;
                        if (dayMap[j].solar === null || recordSolar > dayMap[j].solar) dayMap[j].solar = recordSolar;
                    }
                }
            }

            for (let j = 0; j < dayMap.length; j++) {
                if (dayMap[j].power === null && dayMap[j].solar === null) {
                    let prevIndex = j - 1;
                    let nextIndex = j + 1;

                    while (prevIndex >= 0 && dayMap[prevIndex].power === null) prevIndex--;
                    while (nextIndex < dayMap.length && dayMap[nextIndex].power === null) nextIndex++;

                    if (prevIndex >= 0 && nextIndex < dayMap.length) {
                        let daysBetween = nextIndex - prevIndex;
                        let dayFraction = (j - prevIndex) / daysBetween;

                        dayMap[j].power = dayMap[prevIndex].power + dayFraction * (dayMap[nextIndex].power - dayMap[prevIndex].power);
                        dayMap[j].solar = dayMap[prevIndex].solar + dayFraction * (dayMap[nextIndex].solar - dayMap[prevIndex].solar);
                    }
                }
            }

            function generateChartOptions(title) {
                return {
                    scales: {
                        x: {
                            type: 'category',
                            title: { display: true, text: 'Date' },
                            grid: { color: 'rgba(255, 255, 255, 0.1)' },
                            ticks: { color: 'rgba(255,255,255,0.5)' }
                        },
                        y: {
                            beginAtZero: false,
                            title: { display: true, text: 'kWh' },
                            grid: { color: 'rgba(255, 255, 255, 0.1)' },
                            ticks: { color: 'rgba(255,255,255,0.5)' }
                        }
                    },
                    plugins: {
                        title: { display: true, text: title, color: 'white' },
                        legend: {
                            labels: {
                                color: 'white',
                                filter: (item) => item.text != "0"
                            },
                            display: true,
                            position: 'top'
                        },
                        tooltip: {
                            callbacks: {
                                label: (item) => {
                                    if (item.dataset.label === "0") return '';

                                    const data = item.dataset.data;
                                    const currentValue = data[item.dataIndex];
                                    const previousValue = item.dataIndex > 0 ? data[item.dataIndex - 1] : null;

                                    let gainText = '';
                                    if (previousValue !== null && item.dataset.label == "Power") {
                                        const gain = currentValue - previousValue;
                                        const fixedRate = (gain * 0.30613) + 0.88121
                                        gainText = ` (Gain: ${gain > 0 ? '+' : ''}${gain.toFixed(2)}  ||  $${fixedRate.toFixed(2)})`;
                                    }

                                    if (previousValue !== null && item.dataset.label == "Solar") {
                                        const gain = currentValue - previousValue
                                        const fixedRate = gain * 0.07;
                                        gainText = ` (Gain: ${gain > 0 ? '+' : ''}${gain.toFixed(2)}  ||  $${fixedRate.toFixed(2)})`;
                                    }

                                    return `${item.dataset.label}: ${parseInt(item.formattedValue.replace(/\,/g, ""))}${gainText}`;
                                }
                            }
                        }
                    },
                    maintainAspectRatio: false,
                    responsive: true,
                    aspectRatio: 4,
                    elements: { line: { tension: 0.4 } },
                    layout: { padding: { left: 10, right: 10, top: 10, bottom: 10 } },
                };
            }

            function generateChartData(labels, data, label, colour) {
                return {
                    labels,
                    datasets: [
                        { label, data, borderColor: `rgba(${colour}, 1)`, backgroundColor: `rgba(${colour}, 0.2)`, borderWidth: 1, lineTension: 0, fill: true }
                    ]
                };
            }

            const dates = dayMap.map(data => data.date);
            const powerData = dayMap.map(data => data.power);
            const solarData = dayMap.map(data => data.solar);

            const powerChartOptions = generateChartOptions('Power Consumption');
            const solarChartOptions = generateChartOptions('Solar Generation');
            const powerChartData = generateChartData(dates, powerData, 'Power', "255,0,0");
            const solarChartData = generateChartData(dates, solarData, 'Solar', "0,255,0");

            setPowerChart(<Line options={powerChartOptions} data={powerChartData} />);
            setSolarChart(<Line options={solarChartOptions} data={solarChartData} />);
        })

    }

    window.addEventListener('resize', function () {
        const scaledTableContainer = document.getElementById('scaledTableContainer');
        const mainDiv = document.getElementById("mainDiv");
        const powerChartContainer = document.getElementById("powerChartContainer");
        const solarChartContainer = document.getElementById("solarChartContainer");

        if (scaledTableContainer) {
            const scaleFactor = Math.min(window.innerWidth / 1920, window.innerHeight / 1080);
            const fontSize = scaleFactor * 16;
            scaledTableContainer.style.fontSize = `${fontSize}px`;
            if (mainDiv) {
                const boundingRect = scaledTableContainer.getBoundingClientRect();
                mainDiv.style.height = `${boundingRect.height}px`;
            }
            if (powerChartContainer) powerChartContainer.style.width = window.innerWidth < 1000 ? "100vw" : "95vw";
            if (solarChartContainer) solarChartContainer.style.width = window.innerWidth < 1000 ? "100vw" : "95vw";
        }
    });

    window.dispatchEvent(new Event('resize'));

    return (
        <div className="mainDiv" id="mainDiv">
            <div className="toplevel">
            </div>
            <div id="powerChartContainer" style={{ margin: '0 auto', marginBottom: "20px", height: '40vh', width: '95vw' }}>
                {powerChart}
            </div>
            <div id="solarChartContainer" style={{ margin: '0 auto', marginBottom: "20px", height: '40vh', width: '95vw' }}>
                {solarChart}
            </div>
            <div className="secondlevel" id="scaledTableContainer">
                <table className="norm" id="sleep-log" />
                <table id="sleep-stats" />
            </div>
        </div>
    )

}