import React from 'react';
import { Link } from 'react-router-dom';

export default function Home() {
    const linkStyle = {
        color: '#ffffff',
        textDecoration: 'none',
        padding: '10px 20px',
        backgroundColor: '#333',
        borderRadius: '5px',
        margin: '10px',
        display: 'inline-block',
    };

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        margin: '10px',
        backgroundColor: '#1c1c1c',
        color: '#ffffff',
        fontFamily: 'Arial, sans-serif',
    };

    const headerStyle = {
        marginBottom: '30px',
    };

    return (
        <div style={containerStyle}>
            <h1 style={headerStyle}>Welcome to this data tracking site</h1>
            <Link to="/sleep" style={linkStyle}>
                Non-24 Sleeping Log
            </Link>
            <br />
            <Link to="/power" style={linkStyle}>
                Energy Usage & Solar Intake
            </Link>
            <br />
            <Link to="/lyrics" style={linkStyle}>
                Chinese Lyrics Transcriptions
            </Link>
        </div>
    );
};